import * as React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function TermsPage() {
  return (
    <Layout>
      <Seo title="Terms of Use" />
      <Navbar />

      <section id="hero" style={{ paddingTop: "60px" }}>
        <div className="container">
          <div className="row pt-5 pb-5 justify-content-center align-items-center">
            <div className="col-lg-12 pt-lg-0 d-flex align-items-center justify-content-center">
              <div data-aos="zoom-out">
                <h1 className="fs-1">Terms Of Use</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-2 d-flex align-items-center justify-content-center">
              <div data-aos="zoom-out">
                <h3 className="mb-3">Terms Of Use</h3>
                <p
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.6" }}
                >
                  The DataAlp only shows whats already an intrinsic part of your
                  dataset and so can not be hold accountable for any
                  consequences of any application by the user, of the insights
                  shared in the report. While using the trained model for
                  predictions, the accuracy is supposed to be close to the test
                  dataset predictions. However, there can always be exceptions
                  in the real world. The DataAlp trained the model to best of
                  its capacity based on the provided dataset and can not be hold
                  accountable for any predictions and consequences. Moreover,
                  any tips made to support DataAlp are only refundable if the
                  company agrees, while payments(tips) made to 'Hire An Alp'
                  will be non-refundable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}
